<template>
  <li :class="{ active: !item.NotificationSeenId }">
    <span style="margin-right: 10px; margin-left: unset; padding: 1px;" class="circle"></span>
    <f7-link panel-close class="no-ripple" :href="'/notifications/view/' + item.NotificationKey">{{ item.Title }}</f7-link>
  </li>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotificationNavigationComponent",
  props: {
    item: Object,
  },
});
</script>
